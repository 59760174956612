import * as React from "react"
import Layout from "../../components/layout"
import WideImage from "../../components/WideImage"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from "../../components/seo";
import Navbar from "../../components/navbar";
import ProductImage from "../../components/ProductImage"


const ProductsPage = () => (

  <Layout className="page platform-page">


    <Seo title={"Platform"} />

      <section className="py-3 container extra-header">
        <div className="row py-lg-3">
          <div className="col-lg-8 col-md-8 mx-auto">
            <h1 className="fw-light">Huey Platform</h1>
            <p className="lead">
              Huey is a real-time platform for improving energy and water efficiency in households.
            </p>
          </div>
        </div>
      </section>

      <div className="album py-5 bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 g-3">

            <div className="col">
              <a href="/platform/huey-mobile" className="product-card card shadow link-unstyled" role="button">
                <StaticImage
                  layout="fullWidth"
                  aspectRatio={3 / 2}
                  alt=""
                  src="../../images/huey-mobile-43-800.jpeg"
                  //formats={["auto", "webp", "avif"]}
                />
                <div className="title-band">
                  <h5 className="card-title fw-bold">Huey Mobile</h5>
                  <h6 className="card-subtitle mb-2">Mobile App</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    Graph display of live, daily and weekly data. Notifications for higher than expected values.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">Currently undergoing field trials. Due for commercial release mid 2023.</small>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
  </Layout>
)

export default ProductsPage
